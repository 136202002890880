import React, { useRef, useEffect, useState, useLayoutEffect, forwardRef} from 'react';
import { gsap } from 'gsap';
import { useGSAP } from '@gsap/react';
import './AboutMe.css';

const About = forwardRef((props, ref) => { 

  const aboutMeRef = useRef(null);
  const [odlBorderRadius, setBorderRadius] = useState(getRandomBorderRadius());

  function getRandomBorderRadius() {
    let topLeft;
    let topRight;
    let bottomRight;
    let bottomLeft;
    if(window.innerWidth < 600){
      topLeft = Math.random() * 20;
      topRight = Math.random() * 20;
      bottomRight = Math.random() * 20;
      bottomLeft = Math.random() * 20;
      return `${topLeft}% ${topRight}% ${bottomRight}% ${bottomLeft}%`;
    }
    else{
      topLeft = Math.random() * 100;
      topRight = Math.random() * 100;
      bottomRight = Math.random() * 100;
      bottomLeft = Math.random() * 100;
      return `${topLeft}% ${topRight}% ${bottomRight}% ${bottomLeft}%`;
    }
  }

  useLayoutEffect(() => {
    if (aboutMeRef.current) {
      gsap.fromTo(
        '.aboutMe',
        { borderRadius: aboutMeRef.current.style.borderRadius },
        {
          borderRadius: odlBorderRadius,
          delay: 3,
          duration: 2,
          ease: "power4.inOut",
          onComplete: () => {
            gsap.set('.aboutMe', { borderRadius: odlBorderRadius });
            const newBorderRadius = getRandomBorderRadius();
            setBorderRadius(newBorderRadius);
          }
        }
      );
    }
  }, [odlBorderRadius]);
    
    /*useGSAP(
        () => {
        }
    )*/

    return (
        <section ref={ref} className='skillsAboutMe'>
                <div ref={aboutMeRef} className='aboutMe'>
                    <h2>About me</h2>
                    <p>
                    My name is Aleksander, I am 19 years old and I live in Poland. I am a young, ambitious programmer with a great passion for creating modern technological 
                    solutions. Despite my young age, I am highly motivated to learn and grow, and I am constantly expanding my programming skills. 
                    I am determined to grow in this industry and realize my full potential. I am looking for opportunities that will allow me to gain experience and 
                    contribute to the success of the company where I will work. My energy, fresh outlook and willingness to continuously improve make me ready for new challenges.
                    </p>
                    
                </div>
                <div className='skills'>
                    <div id='LeftSkills'>
                        <i className="devicon-html5-plain colored"></i>
                        <i className="devicon-css3-plain colored"></i>
                        <i className="devicon-javascript-plain colored"></i>
                        <i className="devicon-react-original colored"></i>
                        <i className="devicon-nodejs-plain-wordmark colored"></i>
                    </div>
                    <div id='RightSkills'>
                        <i className="devicon-threejs-original"></i>
                        <i className="devicon-blender-original colored"></i>
                        <i className="devicon-cplusplus-plain colored"></i>
                        <i className="devicon-unrealengine-original"></i>
                        <i className="devicon-vscode-plain colored"></i>
                    </div>
                </div>
            </section>
    )
});

export default About;