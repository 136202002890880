import React, { useEffect, useRef } from 'react';
//import { gsap } from 'gsap';

const Smoke = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const particles = [];

    const createParticle = () => {
      return {
        //x: canvas.width / 2,
        x: Math.random() * 60 + ((canvas.width / 2)- 30),
        y: canvas.height,
        radius: Math.random() * 20 + 10,
        //color: `#050C9C`,
        color: `rgba(242, 234, 211, ${Math.random() * 0.5 + 0.3})`,
        speedX: Math.random() * 2 - 1,
        speedY: -Math.random() * 3 - 1,
      };
    };

    const drawParticle = (particle) => {
      ctx.beginPath();
      ctx.arc(particle.x, particle.y, particle.radius, 0, Math.PI * 2, false);
      ctx.fillStyle = particle.color;
      ctx.fill();
      ctx.closePath();
    };

    const updateParticles = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      particles.forEach((particle, i) => {
        particle.x += particle.speedX;
        particle.y += particle.speedY;
        particle.radius *= 0.98;

        if (particle.radius < 1) {
          particles.splice(i, 1);
        } else {
          drawParticle(particle);
        }
      });

      if (particles.length < 80) {
        particles.push(createParticle());
      }

      requestAnimationFrame(updateParticles);
    };

    updateParticles();

    return () => cancelAnimationFrame(updateParticles);
  }, []);

  return <canvas className='smoke' ref={canvasRef} width={400} height={400} />;
};

export default Smoke;