import React, {useState, useEffect, useRef, useLayoutEffect} from "react";
import Header from "./Header.jsx"
import Hero from "./Hero.js"
import Projects from "./Projects.js";
import About from "./About.jsx";
import Contact from "./Contact.js";

function App(){

  const aboutRef = useRef(null);
  const contactRef = useRef(null);
  const projectsRef = useRef(null);
  const [stickyClass, setStickyClass] = useState(''); // Add a state variable to store the sticky class

  function stickyOrNot(isAlreadyLoaded) {
    if(aboutRef.current || isAlreadyLoaded){
      console.log('offsetTop: ' + aboutRef.current.offsetTop)
      if (window.scrollY > aboutRef.current.offsetTop || window.scrollY <= 10 || isAlreadyLoaded) {
        return 'sticky';
      } else {
        return '';
      }
    } else {
    return '';
    }
  }

  const scrollToContact = () => {
    if(contactRef.current){
      contactRef.current.scrollIntoView({behavior: 'smooth'});
    }
  }

  const scrollToProjects = () => {
    if(projectsRef.current){
      projectsRef.current.scrollIntoView({behavior: 'smooth'});
    }
  }

  useLayoutEffect(() => {
    setStickyClass(stickyOrNot(true));
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setStickyClass(stickyOrNot())
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [stickyClass]);

  return (
    <>
      <Header stickyClass={stickyClass} scrollToProjects={scrollToProjects} scrollToContact={scrollToContact}/>
      <Hero scrollToContact={scrollToContact}/>
      <Projects ref={projectsRef}/>
      {/*<AboutMe/>*/}
      <About ref={aboutRef}/>
      <Contact ref={contactRef}/>
    </>
  );
}

export default App;