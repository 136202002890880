import React, { useState, useEffect} from 'react';
import './Header.css';

function Header(props) {

    return (
        <>
            <nav className={props.stickyClass}>
                <div id="LeftNav">
                    <a href='/'>Aleksander</a>
                </div>
                <div id="MidNav">

                </div>

                <input type="checkbox" id="mobileMenu"/>
                <label htmlFor="mobileMenu" className="openSideBar">
                    <ion-icon name="menu-outline"></ion-icon>
                </label>
                
                
                <div className="menu">
                    <label htmlFor="mobileMenu" className="closeSideBar">
                        <ion-icon name="close-outline"></ion-icon>
                    </label>
                    <ul>
                        <li onClick={props.scrollToProjects}>Projects</li>
                        <li onClick={props.scrollToContact}>Contact</li>
                    </ul>
                </div>
                <label id="overlay" htmlFor="mobileMenu"></label>

                {/*<div className='menuIcon' style={{color: '#F2EAD3'}}>
                    <ion-icon name="menu-outline"></ion-icon>
                </div>
                
                <div id="RightNav">
                    <ul>
                        <li><a href='/'>Projects</a></li>
                        <li><a href='/'>Contact</a></li>
                    </ul>
                </div>*/}
            </nav>
        </>
    );
}

export default Header;