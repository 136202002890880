import './Contact.css';
import React, { useState, useEffect, useRef, useCallback, forwardRef } from 'react';

const Contact = forwardRef((props, ref) => {

    const form = useRef(null);
    const [score, setScore] = useState(0);
    const [snakeBody, setSnakeBody] = useState([{ x: 5, y: 10 }]);
    const [velocity, setVelocity] = useState({ x: 0, y: 0 });
    const [food, setFood] = useState({ x: 0, y: 0 });
    const [gameOver, setGameOver] = useState(false);
    const [startGame, setStartGame] = useState(false);
    const [buttonText, setButtonText] = useState('Play');
    const playBoardRef = useRef(null);
    const setIntervalId = useRef(null);

    const changeFoodPosition = useCallback(() => {
        setFood({
            x: Math.floor(Math.random() * 30) + 1,
            y: Math.floor(Math.random() * 30) + 1,
        });
    }, []);

    const handleGameOver = useCallback((isGameOvered) => {
        clearInterval(setIntervalId.current);
        if(isGameOvered) {
            alert(`Game over! Press OK to replay`);
        }
        window.location.reload();
    }, []);

    const changeDirection = useCallback((e) => {
        e.preventDefault();
        if (e.key === "ArrowUp" && velocity.y !== 1) {
            setVelocity({ x: 0, y: -1 });
        } else if (e.key === "ArrowDown" && velocity.y !== -1) {
            setVelocity({ x: 0, y: 1 });
        } else if (e.key === "ArrowLeft" && velocity.x !== 1) {
            setVelocity({ x: -1, y: 0 });
        } else if (e.key === "ArrowRight" && velocity.x !== -1) {
            setVelocity({ x: 1, y: 0 });
        }
    }, [velocity]);

    const handleTouchStart = (e) => {
        e.preventDefault();
        e.target.dataset.startX = e.touches[0].clientX;
        e.target.dataset.startY = e.touches[0].clientY;
    };

    const handleTouchMove = (e) => {
        e.preventDefault();
        const endX = e.touches[0].clientX;
        const endY = e.touches[0].clientY;
        const startX = parseFloat(e.target.dataset.startX);
        const startY = parseFloat(e.target.dataset.startY);

        const diffX = endX - startX;
        const diffY = endY - startY;

        if (Math.abs(diffX) > Math.abs(diffY)) {
            if (diffX > 0 && velocity.x !== -1) {
                setVelocity({ x: 1, y: 0 }); // Swiped right
            } else if (diffX < 0 && velocity.x !== 1) {
                setVelocity({ x: -1, y: 0 }); // Swiped left
            }
        } else {
            if (diffY > 0 && velocity.y !== -1) {
                setVelocity({ x: 0, y: 1 }); // Swiped down
            } else if (diffY < 0 && velocity.y !== 1) {
                setVelocity({ x: 0, y: -1 }); // Swiped up
            }
        }
    };

    const updateBoard = useCallback((newSnakeBody) => {
        const playBoard = playBoardRef.current;
        if (playBoard) {
            playBoard.innerHTML = '';
            const foodElement = document.createElement('div');
            foodElement.className = 'food';
            foodElement.style.gridArea = `${food.y} / ${food.x}`;
            playBoard.appendChild(foodElement);

            newSnakeBody.forEach(segment => {
                const snakeElement = document.createElement('div');
                snakeElement.className = 'head';
                snakeElement.style.gridArea = `${segment.y} / ${segment.x}`;
                playBoard.appendChild(snakeElement);
            });
        }
    }, [food]);

    const initGame = useCallback(() => {
        if (gameOver) return handleGameOver(true);

        const newSnakeBody = [...snakeBody];
        const snakeHead = { ...newSnakeBody[0] };

        snakeHead.x += velocity.x;
        snakeHead.y += velocity.y;

        if (snakeHead.x <= 0 || snakeHead.x > 30 || snakeHead.y <= 0 || snakeHead.y > 30) {
            setGameOver(true);
            return;
        }

        for (let i = newSnakeBody.length - 1; i > 0; i--) {
            newSnakeBody[i] = newSnakeBody[i - 1];
        }
        newSnakeBody[0] = snakeHead;

        if (snakeHead.x === food.x && snakeHead.y === food.y) {
            changeFoodPosition();
            setScore(prevScore => prevScore + 1);
            newSnakeBody.push({ x: food.x, y: food.y });
        }

        else if (newSnakeBody.slice(1).some(segment => segment.x === snakeHead.x && segment.y === snakeHead.y)) {
                setGameOver(true);
                return;
        }

        setSnakeBody(newSnakeBody);
        updateBoard(newSnakeBody);
    }, [snakeBody, velocity, food, gameOver, changeFoodPosition, updateBoard, handleGameOver]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(form.current);
        const formObject = Object.fromEntries(formData);
        fetch('/send-email', {
            method: 'POST',
            body: JSON.stringify(formObject),
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => console.log(data))
            .catch((error) => console.error(error));
    };

    const StartGame = () => {
        let isGameStarted = !startGame;
        setStartGame(!startGame);
        if(isGameStarted){
            setButtonText('Exit');
        }
        else{
            handleGameOver(false);
        }
    }

    useEffect(() => {
        if(startGame){
            setIntervalId.current = setInterval(initGame, 100);
            document.addEventListener('keydown', changeDirection);
            document.addEventListener('touchstart', handleTouchStart, { passive:false });
            document.addEventListener('touchmove', handleTouchMove, { passive:false });
        }

        return () => {
            clearInterval(setIntervalId.current);
            document.removeEventListener('keydown', changeDirection);
            document.removeEventListener('touchstart', handleTouchStart);
            document.removeEventListener('touchmove', handleTouchMove);
        };
    }, [initGame, changeDirection, startGame]);

    useEffect(() => {
        changeFoodPosition();
        setSnakeBody([{ x: 5, y: 10 }]);
    }, [changeFoodPosition]);

    return (
        <section ref={ref} className='contact' id='contact'>
            <div className='contactForm'>
                <h2>Contact with me</h2>
                <form ref={form} key={1} onSubmit={handleSubmit}>
                    <div>
                        <input type="email" name="email" placeholder="Email" required />
                    </div>
                    <h4>Message</h4>
                    <textarea name="message" id="message" required></textarea>
                    <div className="action">
                        <button type="submit">Send</button>
                    </div>
                </form>
            </div>
            <div className='miniGame'>
                <div className="wrapper">
                    <div className="game-details">
                        <span className="score">Score: {score}</span>
                        <div className="actionPlay">
                            <button onClick={StartGame}>{buttonText}</button>
                        </div>
                    </div>
                    <div className="play-board" ref={playBoardRef}></div>
                </div>
            </div>
        </section>
    );
});

export default Contact;
