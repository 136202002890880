import React, {useEffect, useRef, forwardRef} from 'react'
import './Projects.css'

function transform(section){
  const offsetTop = section.parentElement.offsetTop;
  const scrollSection = section.querySelector('.scroll')
  let precentage = ((window.scrollY - offsetTop) / window.innerHeight) * 100;
  precentage = precentage < 0 ? 0 : precentage > 400 ? 400 : precentage;
  scrollSection.style.transform = `translate3d(${-(precentage)}vw, 0, 0)`
}

const Projects = forwardRef((props, ref) => {

  const projectsRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      transform(projectsRef.current);
    };

    window.addEventListener('scroll', handleScroll, { passive: false });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <section ref={ref} className='projects'>
        <div className='projects-container' key={1} ref={projectsRef}>
          <div className='scroll' style={{ scrollSnapType: 'y mandatory' }}>
            <div className='project' id='1'>
              <div className='leftText'>
                <div className='desc'>
                  <h2 onClick={() => window.open('https://fruitify.pl', '_blank')}>Fruitify</h2>
                  <p>I designed and developed an online store for fruit and vegetable wholesaler Fruitify. 
                    The site was designed with simplicity and functionality in mind, offering users the ability to browse the fresh fruit and vegetable offerings and 
                    easily place orders online without the need to register. The entire purchase process has been optimized to provide a fast and intuitive experience for the customer. 
                    The site is also responsive, meaning it works seamlessly on a variety of devices, from computers to smartphones.
                  </p>
                </div>
                <div className='skillsAndTech'>
                  <p>
                    What i used to build this project: 
                  </p>
                  <p>
                    <i className="devicon-html5-plain colored" style={{marginRight: '10px'}}></i> 
                    <i className="devicon-css3-plain colored" style={{marginRight: '10px'}}></i> 
                    <i className="devicon-javascript-plain colored" style={{marginRight: '10px'}}></i> 
                    <i className="devicon-nodejs-plain-wordmark colored" style={{marginRight: '10px'}}></i>
                    <i className="devicon-mysql-plain-wordmark colored"></i>
                  </p>
                </div>
              </div>
              <div className='screen'>
                <img src='screenFruitify.png' alt='Fruitify' onClick={() => window.open('https://fruitify.pl', '_blank')}/>
              </div>
              {/*<div className='mockup' onClick={() => window.open('https://fruitify.pl', '_blank')}>
                <LaptopMockup mockupPath={'MockupFruitify.gltf'} />
              </div>*/}
            </div>
            <div className='project' id='2'>
              <div className='leftText'>
                <div className='desc'>
                  <h2 onClick={() => window.open('https://detaildynasty.pl', '_blank')}>Detail Dynasty</h2>
                  <p>
                  I independently developed a minimalist one-page website for a mobile car detailer. The site was designed with an emphasis on simplicity and 
                  clarity to effectively communicate the detailing services offered. The entire layout is modern and responsive, adapted for viewing on mobile devices. 
                  Users can easily learn about the services, view a gallery of “before and after” photos, and contact the service provider directly. The design features a 
                  minimalist design, with large, easy-to-read fonts and a subdued color scheme, which emphasizes the professionalism and quality of the services.
                  </p>
                </div>
                <div className='skillsAndTech'>
                  <p>
                    What i used to build this project: 
                  </p>
                  <p>
                    <i className="devicon-html5-plain colored" style={{marginRight: '10px'}}></i> 
                    <i className="devicon-css3-plain colored" style={{marginRight: '10px'}}></i> 
                    <i className="devicon-javascript-plain colored" style={{marginRight: '10px'}}></i>
                  </p>
                </div>
              </div>
              <div className='screen'>
                <img src='./screenDetailDynasty.png' alt='DetailDynasty' onClick={() => window.open('https://detaildynasty.pl', '_blank')}/>
              </div>
              {/*<div className='mockup' onClick={() => window.open('https://detaildynasty.pl', '_blank')}>
                  <LaptopMockup mockupPath={'MockupDetail.gltf'}/>
              </div>*/}
            </div>
            <div className='project' id='3'>
              <div className='leftText'>
                <div className='desc'>
                  <h2 onClick={() => window.open('https://alekscode.pl', '_blank')}>This portfolio</h2>
                  <p>
                  I have completed an interactive portfolio project, which was created using React. The portfolio showcases not only my programming skills, 
                  but also my creative approach to developing modern applications. The site includes some interesting features that attract users' attention, 
                  such as the classic Snake mini-game, text typing animation, and a smoke effect that adds a unique touch. Each element is carefully designed to not only look attractive, 
                  but also work smoothly on various devices. This portfolio is both a showcase of my technical skills and proof of my ability to combine functionality 
                  with aesthetics in a modern web design.
                  </p>
                </div>
                <div className='skillsAndTech'>
                  <p>
                    What i used to build this project: 
                  </p>
                  <p>
                    <i className="devicon-html5-plain colored" style={{marginRight: '10px'}}></i> 
                    <i className="devicon-css3-plain colored" style={{marginRight: '10px'}}></i> 
                    <i className="devicon-javascript-plain colored" style={{marginRight: '10px'}}></i> 
                    <i className="devicon-react-original colored" style={{marginRight: '10px'}}></i>
                    <i className="devicon-nodejs-plain-wordmark colored"></i>
                  </p>
                </div>
              </div>
              <div className='screen'>
                <img src='./screenPortfolio.png' alt='Alekscode' onClick={() => window.open('https://alekscode.pl', '_blank')}/>
              </div>
              {/*<div className='mockup' onClick={() => window.open('https://detaildynasty.pl', '_blank')}>
                  <LaptopMockup mockupPath={'MockupDetail.gltf'}/>
              </div>*/}
            </div>
          </div>
        </div>
      </section>
    </>
  )
});

export default Projects;