import './Hero.css'
import Smoke from './Smoke';
import ConsoleText from './ConsoleText';
import React, { useState, useEffect, useRef} from 'react';

function Hero({ scrollToContact }){
    
    /*const [title, setTitle] = useState('Developer');
    const intervalIdRef = useRef(null);*/
    
    const titles = ['Developer', 'Designer', 'Creator'];
    
    function addSmoke() {
        if(window.innerWidth > 1000){
            return (
                <>
                    <Smoke id='Smoke1'/>
                    <Smoke id='Smoke2'/>
                </>
            )
        }
        else{
            return (
                <Smoke/>
            )
        }
    }

    return(
        <>
            <section className='hero'>
                {addSmoke()}
                <div className='mainHeader'>
                   <ConsoleText
                    words={titles}
                    id="text"
                   />
                    <p>Hi, my name is Aleksander.</p>
                    <div className="action">
                        <button onClick={scrollToContact}>Contact</button>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Hero;