import React, { useEffect, useRef } from 'react';

const ConsoleText = ({ words, id, colors }) => {
  const targetRef = useRef(null);
  const consoleRef = useRef(null);

  useEffect(() => {
    let intervalId1, intervalId2;

    const consoleText = (words, id, colors) => {
      // ... (rest of the consoleText function remains the same)
  
      if (colors === undefined) colors = ['#f2ead3'];
      var visible = true;
      var con = consoleRef.current;
      var letterCount = 1;
      var x = 1;
      var waiting = false;
      var target = targetRef.current;
      target.setAttribute('style', 'color:' + colors[0])
      window.setInterval(function() {
        if (letterCount === 0 && waiting === false) {
          waiting = true;
          target.innerHTML = words[0].substring(0, letterCount)
          window.setTimeout(function() {
            var usedColor = colors.shift();
            colors.push(usedColor);
            var usedWord = words.shift();
            words.push(usedWord);
            x = 1;
            target.setAttribute('style', 'color:' + colors[0])
            letterCount += x;
            waiting = false;
          }, 1000)
        } 
        // deleting text
        else if (letterCount === words[0].length + 1 && waiting === false) {
          waiting = true;
          window.setTimeout(function() {
            x = -1;
            letterCount += x;
            waiting = false;
          }, 1000)
        } 
        // writing text
        else if (waiting === false) {
          target.innerHTML = words[0].substring(0, letterCount)
          letterCount += x;
        }
      }, 120)
      window.setInterval(function() {
        if (visible === true) {
          con.className = 'console-underscore hidden'
          visible = false;
  
        } else {
          con.className = 'console-underscore'
  
          visible = true;
        }
      }, 400)
    };

    consoleText(words, id, colors);

    return () => {
      window.clearInterval(intervalId1);
      window.clearInterval(intervalId2);
    };
  },[targetRef]);

  return (
    <div className='console-container'>
    <span id={id} ref={targetRef}>
    </span>
    <div className='console-underscore' ref={consoleRef} id='console'>
        &#95;
    </div>
</div>
  );
};

export default ConsoleText;